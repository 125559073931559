import {api} from "./api";

export class Profile {
	prefix() {
		return "auth/profile"
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	create(obj) {
		return api.post(`${this.prefix()}`, obj)
	}

	update(uid, obj) {
		return api.put(`${this.prefix()}/${uid}`, obj)
	}

	inventory(uid) {
		return api.get(`/users/${uid}/inventory`)
	}
}

export default new Profile()