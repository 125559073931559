<template>
	<div class="inventory-item text-center p-2 py-3">
		<span class="rarity" :style="{backgroundColor: rarityColour}">{{ rarity }}</span>
		<h3 class="qty">x{{ item.quantity_owned }}</h3>
		<img v-if="!is3DModel" class="img-fluid img-sm" :src="item.item.sprite_uri" alt="Item image">
		<GenericModelViewer @click="onModelClicked" v-if="is3DModel" :object_uri="item.item.sprite_uri" :poster_uri="item.item.poster_uri"></GenericModelViewer>
		<h3 class="name">{{ item.item.name }}</h3>
	</div>
</template>

<script>
	import Item from "@/api/item"
	import GenericModelViewer from "../3d/GenericModelViewer";
	
	export default {
		name: "InventoryItem",
		components: {GenericModelViewer},
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		computed: {
			is3DModel: function () {
				return this.item.item.sprite_uri.indexOf(".glb") !== -1 || this.item.item.sprite_uri.indexOf(".obj") !== -1
			},
			
			rarity: function () {
				switch (this.item.item.rarity) {
					case "common":
						return "Common";
					case "uncommon":
						return "Uncommon";
					case "rare":
						return "Rare";
					case "epic":
						return "Epic";
					case "legendary":
						return "Legendary";
					default:
						return "???";
				}
			},
			rarityColour: function () {
				switch (this.item.item.rarity) {
					case "common":
						return "green";
					case "uncommon":
						return "blue";
					case "rare":
						return "yellow";
					case "epic":
						return "purple";
					case "legendary":
						return "orange";
					default:
						return "grey";
				}
			}
		},
		methods: {
			onModelClicked: function (data) {
				console.log("clicked", this.item.item);
				
				if (this.item.item.sprite_uri.endsWith(".glb")) {
					data.then(res => this.upload(res))//Already blob
					return
				}
				
				fetch(data)
					.then(res => {
						res.blob().then(b => this.upload(b))
					})
			},
			
			upload: function (b) {
				const formData = new FormData();
				formData.append("file", b)
				formData.append("wallet_addr", this.$store.state.wallet_addr)
				
				Item.updateLogo(this.item.item.token_addr, formData).then(resp => {
					console.log("item logo uploaded: ", resp.data)
				})
				
			}
		}
	}
</script>

<style scoped>
	.inventory-item {
		position: relative;
		border: 1px solid #0a9fb9;
		background: #1e1e1f;
		border-radius: 8px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
		min-height: 100%;
	}
	
	.name, .qty {
		color: white;
		position: absolute;
		bottom: 0;
		text-align: left;
		width: 100%;
		pointer-events: none;
	}
	
	.qty {
		text-align: right;
		top: 0;
		right: 15px;
		height: 30px;
	}
	
	.inventory-item:hover {
		background: rgba(22, 99, 117, 1);
		transform: scale(1.1);
	}
	
	.img-sm {
		max-height: 100px;
	}
	
	span.rarity {
		border-radius: 15px;
		position: absolute;
		top: 5px;
		left: 5px;
		color: white;
		font-size: 0.8rem;
		padding: 0 5px 0 5px;
	}
</style>