<template>
	<div class="col-12">
		<div class="row mt-3 bg-card p-3">
			<div class="col-md-3 col-lg-3 col-sm-12 img_container">
				<img :src="profile.avatar_url" class="img-fluid" alt="player avatar">
			</div>
			<div class="col-md-9 col-lg-9 col-sm-12">
				<table class="table">
					<tbody>
					<tr>
						<td>Username:</td>
						<td>{{ profile.name }}</td>
					</tr>
					<tr>
						<td>Wallet Addr:</td>
						<td><code>{{ profile.wallet_addr }}</code></td>
					</tr>
					<tr>
						<td>Guild:</td>
						<td>-</td>
					</tr>
					<tr>
						<td>Last Login:</td>
						<td>{{ humanDate }}</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from "moment"
	
	export default {
		name: "PlayerCard",
		props: {
			profile: {
				type: Object,
				required: true
			}
		},
		computed: {
			humanDate: function () {
				return moment.duration(-moment(this.profile.last_login_at).unix()).humanize(true)
			}
		}
	}
</script>

<style scoped>
	h1 {
		font-family: "Prime-Light", sans-serif;
		color: #0a9fb9;
		font-size: 2rem;
	}
	
	h3 {
		color: white;
		font-size: 1.2rem;
	}
	
	td {
		color: white;
		font-size: 1rem;
	}
	
	code {
		font-size: 0.9rem;
	}
	
	.img_container {
		text-align: center;
		vertical-align: middle;
	}
</style>