<template>
	<div class="inventory-item text-center p-2 py-3">
		
		<div v-if="!item.id">
			<span class="placeholder">{{ equipSlot }}</span>
		</div>
		<div v-if="item.id">
			<span class="rarity" :style="{backgroundColor: rarityColour}">{{ rarity }}</span>
			<img class="img-fluid img-sm" :src="item.item.sprite_uri" alt="Item image">
			<h3 class="name">{{ item.item.name }}</h3>
		</div>
	</div>
</template>

<script>
	export default {
		name: "EquipmentSlot",
		props: {
			item: {
				type: Object,
				required: true
			},
			equipSlot: String,
		},
		computed: {
			rarity: function () {
				switch (this.item.item.rarity) {
					case "common":
						return "Common";
					case "uncommon":
						return "Uncommon";
					case "rare":
						return "Rare";
					case "epic":
						return "Epic";
					case "legendary":
						return "Legendary";
					default:
						return "???";
				}
			},
			rarityColour: function () {
				switch (this.item.item.rarity) {
					case "common":
						return "green";
					case "uncommon":
						return "blue";
					case "rare":
						return "yellow";
					case "epic":
						return "purple";
					case "legendary":
						return "orange";
					default:
						return "grey";
				}
			}
		}
	}
</script>

<style scoped>
	.inventory-item {
		position: relative;
		border: 1px solid #0a9fb9;
		background: #1e1e1f;
		border-radius: 8px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
		min-height: 100%;
	}
	
	.name, .qty {
		color: white;
		position: absolute;
		bottom: 0;
		text-align: left;
		width: 100%;
	}
	
	.qty {
		text-align: right;
		top: 0;
		right: 15px;
	}
	
	.inventory-item:hover {
		background: rgba(22, 99, 117, 1);
		transform: scale(1.1);
	}
	
	.img-sm {
		max-height: 100px;
	}
	
	span.rarity {
		border-radius: 15px;
		position: absolute;
		top: 5px;
		left: 5px;
		color: white;
		font-size: 0.8rem;
		padding: 0 5px 0 5px;
	}
	
	span.placeholder {
		color: white;
		text-transform: capitalize;
	}
</style>