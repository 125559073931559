<template>
	<div class="col-12">
		<div class="row mt-3">
			<div class="bg-card col-12">
				<div class="row mt-3 mb-5">
					<div class="col-lg-6 col-md-12 col-sm-12">
						<input class="form-control-lg" placeholder="Search" v-model="search">
					</div>
					<div class="col-lg-6 col-md-12 col-sm-12">
						<div class="row">
							<div class="col-lg-2 col-md-2 col-sm-4 mt-sm-2 m-0 p-0 px-1">
								<button v-on:click="toggle2D" :class="`btn btn-block btn-sm ${show2D ? 'btn-primary' : 'btn-secondary'}`">2D</button>
							</div>
							<div class="col-lg-2 col-md-2 col-sm-4 mt-sm-2 m-0 p-0 px-1">
								<button v-on:click="toggle3D" :class="`btn btn-block btn-sm ${show3D ? 'btn-primary' : 'btn-secondary'}`">3D</button>
							</div>
							<div class="col-lg-2 col-md-2 col-sm-4 mt-sm-2 m-0 p-0 px-1">
								<button v-on:click="toggleWearable" :class="`btn btn-block btn-sm ${showWearable ? 'btn-primary' : 'btn-secondary'}`">Wearable</button>
							</div>
							<div class="col-lg-2 col-md-2 col-sm-4 mt-sm-2 m-0 p-0 px-1">
								<button v-on:click="toggleWearable" :class="`btn btn-block btn-sm ${showWearable ? 'btn-primary' : 'btn-secondary'}`">Land</button>
							</div>
							<div class="col-lg-2 col-md-2 col-sm-4 mt-sm-2 m-0 p-0 px-1">
								<button v-on:click="toggleWearable" :class="`btn btn-block btn-sm ${showWearable ? 'btn-primary' : 'btn-secondary'}`">Pets</button>
							</div>
							<div class="col-lg-2 col-md-2 col-sm-4 mt-sm-2 m-0 p-0 px-1">
								<button v-on:click="toggleWearable" :class="`btn btn-block btn-sm ${showWearable ? 'btn-primary' : 'btn-secondary'}`">Skins</button>
							</div>
						</div>
					</div>
				</div>
				
				<div class="not-found" v-if="filtered.length === 0">
					<i>No Results found</i>
					<p class="mt-2">
						<router-link class="brows-mktplace" to="/marketplace">Browse Marketplace</router-link>
					</p>
				</div>
				
				<div class="row">
					<div class="col-2 col-lg-2 col-sm-4 col-md-3 mb-3" v-for="item in filtered" :key="item.id">
						<InventoryItem class="" :item="item"></InventoryItem>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import InventoryItem from "./InventoryItem";
	import Profile from "@/api/profile"
	
	export default {
		name: "Inventory",
		components: {InventoryItem},
		props: {
			user: String
		},
		data() {
			return {
				search: "",
				show2D: true,
				show3D: true,
				showWearable: true,
				items: []
			}
		},
		computed: {
			filtered: function () {
				return this.items.filter(item => {
					if (!this.show2D && (item.item.display_type === 0 || item.item.sprite_uri.indexOf(".glb") === -1)) {
						return false;
					}
					if (!this.show3D && (item.item.display_type === 1 || item.item.sprite_uri.indexOf(".glb") !== -1)) {
						return false;
					}
					
					if (!this.showWearable && item.item.item_type !== 5) {
						return false;
					}
					
					return this.search !== "" ? item.item.name.toLowerCase().includes(this.search.toLowerCase()) : true;
				})
			}
		},
		methods: {
			getInventory: function () {
				Profile.inventory(this.user).then(r => {
					console.log("resp", r.data)
					this.items = r.data;
				});
			},
			
			toggle2D: function () {
				this.show2D = !this.show2D;
			},
			
			toggle3D: function () {
				this.show3D = !this.show3D;
			},
			
			toggleWearable: function () {
				this.showWearable = !this.showWearable;
			}
		},
		mounted() {
			this.getInventory()
		}
	}
</script>

<style scoped>
	h1 {
		font-family: "Prime-Light", sans-serif;
		color: #0a9fb9;
		font-size: 2rem;
	}
	
	.brows-mktplace {
		color: #0a9fb9;
		text-decoration: underline !important;
	}
	
	.not-found {
		padding-top: 3vh;
		height: 10vh;
		color: white;
		text-align: center;
		font-size: 1.2rem;
	}
	
	.m-0 {
		margin-top: 0 !important;
	}
</style>