<template>
	<model-viewer class="model-view" alt="Item image" ar ar-modes="webxr scene-viewer quick-look" :src="user.sprite_url"></model-viewer>
</template>

<script>
	import "@google/model-viewer"
	
	export default {
		name: "PlayerSprite",
		props: {
			user: Object,
		}
	}
</script>

<style scoped>
	model-viewer {
		--poster-color: transparent;
		height:500px;
		width:100%;
	}
</style>